import http from './http'

const mainUri = '/api/v2/roles'

export function list() {
  return http({
    url: `${mainUri}`,
    method: 'get',
  })
}
